import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { appContext } from '../App';
import DashboardLayoutBasic from './LandingPage';
import CustomerList from './Components/Customer/CustomerList';
import AuthenticationList from './Components/Authentication/AuthenticationList';
import InvoiceList from './Components/Invoices/InvoiceList';
import MonitorHome from './Components/Monitor/MonitorHome';
import DynamicComponentRenderer from './Components/Monitor/DynamicComponentRenderer';
import MachineList from './Components/MachinesModal/MachinesList';
import MachineViewList from './Components/Machines/MachineList';

interface Revalidate {
  validate: () => void;
}

function MainView(props: Revalidate) {
  const [isAuthVerified, setIsAuthVerified] = useState<boolean>(true);

  useEffect(() => {
    props.validate();
  }, [isAuthVerified]);

  return (
    <appContext.Provider value={{ isAuthVerified, setIsAuthVerified }}>
      <Routes>
        <Route path="/customers" element={<DashboardLayoutBasic children={<CustomerList />} />} />
        <Route path="/invoices" element={<DashboardLayoutBasic children={<InvoiceList />} />} />
        <Route path="/monitor" element={<DashboardLayoutBasic children={<MonitorHome />} />} />
        <Route path="/machines" element={<DashboardLayoutBasic children={<MachineViewList />} />} />
        <Route path="/monitor/:id" element={<DashboardLayoutBasic children={<DynamicComponentRenderer />} />} />
        <Route path="/settings/machines" element={<DashboardLayoutBasic children={<MachineList />} />} />
        <Route path="/settings/authentication" element={<DashboardLayoutBasic children={<AuthenticationList />} />} />
        <Route path="/" element={<DashboardLayoutBasic children={<span>Welcome</span>} />} />
      </Routes>
    </appContext.Provider>
  );
}

export default MainView;
