import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/CloseRounded';

interface SingleSelectStringChipProps {
    dataList: string[];
    selectedValue: string | null;
    setSelectedValue: (value: string | null) => void;
    name: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, selectedName: string | undefined, theme: Theme) {
    return {
        fontWeight:
            selectedName === name
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
    };
}

export default function SingleSelectStringChip({
    dataList,
    selectedValue,
    setSelectedValue,
    name,
}: SingleSelectStringChipProps) {
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<string>) => {
        const { value } = event.target;
        setSelectedValue(value);
    };

    const handleDelete = () => {
        setSelectedValue(null);
    };

    return (
        <FormControl sx={{ m: 1, width: '100%' }} size="small">
            <InputLabel id="single-select-string-label">{name}</InputLabel>
            <Select
                labelId="single-select-string-label"
                id="single-select-string"
                value={selectedValue || ''}
                onChange={handleChange}
                input={<OutlinedInput label={name} />}
                sx={{ width: '100%', mr: 1 }}
                renderValue={() => (
                    selectedValue ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 0.5,
                                whiteSpace: 'nowrap',
                                overflowX: 'auto',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': { display: 'none' },
                            }}
                        >
                            <Chip
                                label={selectedValue}
                                size="small"
                                sx={{ borderRadius: 1 }}
                                deleteIcon={
                                    <IconButton
                                        onMouseDown={(event) => event.stopPropagation()}
                                        onClick={handleDelete}
                                        sx={{ p: 0, borderRadius: 1 }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                }
                                onDelete={handleDelete}
                            />
                        </Box>
                    ) : null
                )}
                MenuProps={MenuProps}
            >
                {dataList.map((item) => (
                    <MenuItem
                        key={item}
                        value={item}
                        style={getStyles(item, selectedValue ?? '', theme)}
                    >
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
