import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/CloseRounded';

interface DataItem {
    id: number;
    name: string;
}

interface MultipleSelectChipProps {
    dataList: DataItem[];
    selectedIds: number[];
    setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>;
    name: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, selectedNames: readonly string[], theme: Theme) {
    return {
        fontWeight:
            selectedNames.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip({
    dataList,
    selectedIds,
    setSelectedIds,
    name,
}: MultipleSelectChipProps) {
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<typeof selectedIds>) => {
        const {
            target: { value },
        } = event;
        const ids = typeof value === 'string' ? value.split(',').map(Number) : value;
        setSelectedIds(ids);
    };

    const handleDelete = (idToDelete: number) => {
        const newSelectedIds = selectedIds.filter((id) => id !== idToDelete);
        setSelectedIds(newSelectedIds);
    };

    return (
        <FormControl sx={{ m: 1, width:'100%' }} size="small">
            <InputLabel id="demo-select-small-label">{name}</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                multiple
                value={selectedIds}
                onChange={handleChange}
                input={<OutlinedInput label={name} />}
                sx={{ width: '100%', mr: 1 }}
                renderValue={(selected) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 0.5,
                            whiteSpace: 'nowrap',
                            overflowX: 'auto',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': { display: 'none' },
                        }}
                    >
                        {selected.map((id) => {
                            const item = dataList.find((data) => data.id === id);
                            return item ? (
                                <Chip
                                    key={id}
                                    label={item.name}
                                    size='small'
                                    sx={{
                                        borderRadius: 1,
                                    }}
                                    deleteIcon={
                                        <IconButton
                                            onMouseDown={(event) => event.stopPropagation()}
                                            onClick={() => handleDelete(id)}
                                            sx={{ p: 0, borderRadius: 1 }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    }
                                    onDelete={() => handleDelete(id)}
                                />
                            ) : null;
                        })}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {dataList.map((item) => (
                    <MenuItem
                        key={item.id}
                        value={item.id}
                        style={getStyles(item.name, selectedIds.map(id => dataList.find(data => data.id === id)?.name || ''), theme)}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
