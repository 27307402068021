import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Grid, MenuItem } from '@mui/material';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { AddMachineModalProps, Machine } from './types';


export default function AddMachineModal({
    open,
    handleClose,
    handleSubmit,
    selectedData,
}: AddMachineModalProps) {
    const [modelName, setModelName] = useState<string>('');
    const [vendorId, setVendorId] = useState<number | ''>('');
    const [coinName, setCoinName] = useState<string>('');
    const [hashRate, setHashRate] = useState<number>(0);
    const [hashUnit, setHashUnit] = useState<string>('');
    const [power, setPower] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    

    const [vendors, setVendors] = useState<{ id: number; vendorName: string }[]>([]);
    const [coins, setCoins] = useState<{ coin: string; algorithm: string }[]>([]);

    useEffect(() => {
        axios.get(API_PATH + '/machine_model/vendors', getAxiosConfig()).then((response) => setVendors(response.data));
        axios.get(API_PATH + '/machine_model/currencies', getAxiosConfig() ).then((response) => setCoins(response.data));
    }, []);

    useEffect(() => {
        if (selectedData) {
            setModelName(selectedData.modelName);
            setVendorId(selectedData.vendor.id);
            setCoinName(selectedData.coin.coin);
            setHashRate(selectedData.hashRate);
            setHashUnit(selectedData.hashUnit);
            setPower(selectedData.power);
            setPrice(selectedData.price)
        }
    }, [selectedData]);

    const handleSave = () => {
        const selectedVendor = vendors.find((v) => v.id === vendorId);
        const selectedCoin = coins.find((c) => c.coin === coinName);

        if (!selectedVendor || !selectedCoin) return;

        const newMachine: Machine = {
            id: selectedData?.id || null,
            modelName,
            vendor: selectedVendor,
            coin: selectedCoin,
            hashRate,
            hashUnit,
            isActive:true,
            price,
            power,
        };

        handleSubmit(newMachine);
    };
    const resetForm = () => {
        setModelName('');
        setVendorId('');
        setCoinName('');
        setHashRate(0);
        setHashUnit('');
        setPower(0);
        setPrice(0);
    };

    useEffect(() => {
        if (!open) {
            resetForm();
        }
    }, [open]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ ...modalStyle }}>
                <Typography variant="h6" gutterBottom>
                    {selectedData ? 'Edit Machine' : 'Add Machine'}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Model Name"
                            value={modelName}
                            onChange={(e) => setModelName(e.target.value)}
                            InputProps={{
                                readOnly: !!selectedData,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            label="Vendor"
                            value={vendorId}
                            onChange={(e) => setVendorId(Number(e.target.value))}
                            InputProps={{
                                readOnly: !!selectedData,
                            }}
                        >
                            {vendors.map((vendor) => (
                                <MenuItem key={vendor.id} value={vendor.id}>
                                    {vendor.vendorName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            label="Coin"
                            value={coinName}
                            onChange={(e) => setCoinName(e.target.value)}
                            InputProps={{
                                readOnly: !!selectedData,
                            }}
                        >
                            {coins.map((coin) => (
                                <MenuItem key={coin.coin} value={coin.coin}>
                                    {coin.coin} - {coin.algorithm}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Hash Rate"
                            value={hashRate === 0 ? '' : hashRate}
                            onChange={(e) => setHashRate(Number(e.target.value))}
                            InputProps={{
                                readOnly: !!selectedData,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            select
                            fullWidth
                            label="Hash Unit"
                            value={hashUnit}
                            onChange={(e) => setHashUnit(e.target.value)}
                            InputProps={{
                                readOnly: !!selectedData,
                            }}
                        >
                            {['MH', 'GH', 'TH', 'PH'].map((unit) => (
                                <MenuItem key={unit} value={unit}>
                                    {unit}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Power (W)"
                            value={power === 0 ? '': power}
                            onChange={(e) => setPower(Number(e.target.value))}
                            InputProps={{
                                readOnly: !!selectedData,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Price"
                            value={price === 0 ? '' : price}
                            onChange={(e) => setPrice(Number(e.target.value))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={handleSave}>
                            {selectedData ? 'Update' : 'Add'} Machine
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </Modal>
    );
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
