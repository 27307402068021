import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/CloseRounded';

interface SingleSelectChipProps {
    dataList: { id: number; name: string }[];
    selectedId: number | null;
    setSelectedId: (id: number | null) => void;
    name: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, selectedName: string | undefined, theme: Theme) {
    return {
        fontWeight:
            selectedName === name
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
    };
}

export default function SingleSelectChip2({
    dataList,
    selectedId,
    setSelectedId,
    name,
}: SingleSelectChipProps) {
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<number>) => {
        const value = Number(event.target.value);
        setSelectedId(value);
    };

    const handleDelete = () => {
        setSelectedId(null);
    };

    const selectedItem = dataList.find(item => item.id === selectedId);

    return (
        <FormControl sx={{ m: 1, width: '100%' }} size="small">
            <InputLabel id="single-select-label">{name}</InputLabel>
            <Select
                labelId="single-select-label"
                id="single-select"
                value={selectedId ?? ''}
                onChange={handleChange}
                input={<OutlinedInput label={name} />}
                sx={{ width: '100%', mr: 1 }}
                renderValue={(selected) => {
                    const selectedItem = dataList.find(item => item.id === selected);
                    return selectedItem ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 0.5,
                                whiteSpace: 'nowrap',
                                overflowX: 'auto',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': { display: 'none' },
                            }}
                        >
                            <Chip
                                label={selectedItem.name}
                                size="small"
                                sx={{ borderRadius: 1 }}
                                deleteIcon={
                                    <IconButton
                                        onMouseDown={(event) => event.stopPropagation()}
                                        onClick={handleDelete}
                                        sx={{ p: 0, borderRadius: 1 }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                }
                                onDelete={handleDelete}
                            />
                        </Box>
                    ) : null;
                }}
                MenuProps={MenuProps}
            >
                {dataList.map((item) => (
                    <MenuItem
                        key={item.id}
                        value={item.id}
                        style={getStyles(item.name, selectedItem?.name, theme)}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
