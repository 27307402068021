import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface AddButtonProps {
    onClick: () => void;
    sx?: SxProps<Theme>;
}

const AddButton: React.FC<AddButtonProps> = ({ onClick, sx }) => {
    return (
        <IconButton
            color="primary"
            aria-label="add"
            onClick={onClick}
            sx={{
                position: 'fixed',
                bottom: 56,
                right: 56,
                width: 56,
                height: 56,
                backgroundColor: 'primary.main',
                color: 'white',
                borderRadius: '15%',
                boxShadow: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                    backgroundColor: 'primary.dark',
                    boxShadow: 6,
                },
                ...sx,
            }}
        >
            <AddIcon />
        </IconButton>
    );
};

export default AddButton;
