import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    TextField,
    Box,
    Typography,
    Stack,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    Link,
    Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthXs: {
                    maxWidth: '450px',
                },
            },
        },
    },
});

const Card = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
}));

const LoginPage: React.FC<{ doLogin: (username: string, password: string) => void }> = ({ doLogin }) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [remember, setRemember] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        doLogin(email, password);
    };

    const handleRemember = (event: any) => {
        setRemember(!remember);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box minWidth={'100%'} sx={{
                backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,1) 48%, rgba(25,119,211,0.3) 100%)'
            }}>
            <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
                    <Card sx={{ backgroundColor: '#fff', boxShadow: 2 }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <FormControl fullWidth>
                            <TextField
                                margin="normal"
                                required
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="standard"
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                margin="normal"
                                required
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant="standard"
                            />
                        </FormControl>
                            <FormControlLabel
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: 12,
                                        color: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }}
                                control={<Checkbox value="remember" color="primary" checked={remember} onChange={handleRemember} />}
                                label="Remember me"
                            />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Card>
            </Container>
            </Box>
        </ThemeProvider>
    );
};

export default LoginPage;